import React from "react";
import { Link } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";

import leadroll from "@images/leadroll-icon.png";
import { Fade, Zoom } from "react-reveal";
// import Particles from 'react-particles-js';
import ReadingProgress from "@components/progressBar";

// Inline styling

const termsContent = {
  padding: "10rem 0 5rem 0",
  position: "relative",
  overflow: "hidden",
};

// end of styling

// const params = {
//       "particles": {
//           "number": {
//               "value": 20,
//               "density": {
//                   "enable": false
//               }
//           },
//           "size": {
//               "value": 10,
//               "random": true,
//               "anim": {
//                   "speed": 4,
//                   "size_min": 0.3
//               }
//           },
//           "color": {
//             "value": "#25ddf7"
//           },
//           "line_linked": {
//               "enable": false
//           },
//           "move": {
//               "random": true,
//               "speed": 1,
//               "direction": "top",
//               "out_mode": "out"
//           }
//       },
//       "interactivity": {
//           "events": {
//               "onhover": {
//                   "enable": true,
//                   "mode": "bubble"
//               },
//               "onclick": {
//                   "enable": true,
//                   "mode": "repulse"
//               }
//           },
//           "modes": {
//               "bubble": {
//                   "distance": 250,
//                   "duration": 2,
//                   "size": 0,
//                   "opacity": 0
//               },
//               "repulse": {
//                   "distance": 400,
//                   "duration": 4
//               }
//           }
//       }
// }

const Story = ({ location }) => {
  const target = React.createRef();

  React.useEffect(() => {
    const outerCircle = document.querySelectorAll(".timeline-outer-circle");
    const middleCircle = document.querySelectorAll(".timeline-middle");
    let observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.intersectionRatio > 0) {
          entry.target.classList.add("fade-in");
        } else {
          entry.target.classList.remove("fade-in");
        }
      });
    });

    outerCircle.forEach((entry) => {
      observer.observe(entry);
    });
    middleCircle.forEach((entry) => {
      observer.observe(entry);
    });
  });
  return (
    <>
      <Layout location={location}>
        <SEO title="Story" description="" />
        <div className="story-content" style={termsContent}>
          {/* <Particles
          params={{...params}}
          style={{
                width: '100%',
                position: 'fixed',
                height: '100%',
                top:0
              }} 
          /> */}
          <div className="container">
            <h1 className="text-white big font-playball text-center pb-5 d-none">
              <span className="h1" title="Story Time!">
                Story Time!
              </span>
            </h1>
            <div className="d-flex justify-content-center align-items-center">
              <div className="overlay-img d-flex align-items-center">
                <div className="blob pulse-2"></div>
                <div className="blob pulse-1"></div>
                <div className="blob-overlay"></div>
                <img
                  className="mb-0 img-fluid mx-auto"
                  src={leadroll}
                  alt="logo"
                  width={80}
                  height={80}
                />
              </div>
            </div>

            <div className="story-line">
              <div className="progress-bar vertical">
                <ReadingProgress target={target} />
              </div>
              <div className="content" ref={target}>
                <div className="row justify-content-center mb-5 mt-0 pb-5">
                  <div className="col-md-6 px-5">
                    <div className="content text-center text-white">
                      <Fade bottom>
                        <h5 className="text-teal my-4">Jan 2016</h5>
                        <h2>
                          Finsweet is born and taken home from hospital with
                          $200
                        </h2>
                      </Fade>
                    </div>
                  </div>
                </div>
                <div className="row justify-content-center my-5 pb-5">
                  <div className="timeline-date">
                    <div className="timeline">
                      <div className="timeline-outer-circle"></div>
                      <div className="timeline-middle"></div>
                      <div className="timeline-inner-circle"></div>
                    </div>
                  </div>
                  <div className="col-md-5 px-5 order-md-2">
                    <Fade left>
                      <div className="published">
                        <h5 className="text-teal my-4">Feb 2016</h5>
                      </div>
                      <div className="content">
                        <h3>
                          First client is signed via first ever cold call.
                        </h3>
                      </div>
                    </Fade>
                  </div>
                  <div className="col-md-5 px-5 order-md-1 mt-4 mt-md-0">
                    <div className="text-md-right">
                      <img
                        src="https://assets.website-files.com/5cc19611198b8d7bdfc5fcfb/5cd1c809a124cc7a054762b6_firstcold-comp.gif"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="row justify-content-center my-5 pb-5">
                  <div className="timeline-date">
                    <div className="timeline">
                      <div className="timeline-outer-circle"></div>
                      <div className="timeline-middle"></div>
                      <div className="timeline-inner-circle"></div>
                    </div>
                  </div>
                  <div className="col-md-5 px-5">
                    <Fade right>
                      <div className="published text-right">
                        <h5 className="text-teal my-4">March 2016</h5>
                      </div>
                      <div className="content text-right">
                        <h3>
                          Stopped cold calling after 100 consecutive failed
                          attempts.
                        </h3>
                      </div>
                    </Fade>
                  </div>
                  <div className="col-md-5 px-5 mt-4 mt-md-0">
                    <div className="text-right text-md-left">
                      <img
                        src="https://assets.website-files.com/5cc19611198b8d7bdfc5fcfb/5cd1c807755d00e2f3ebe106_no-comp.gif"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="row justify-content-center my-5 pb-5">
                  <div className="timeline-date">
                    <div className="timeline">
                      <div className="timeline-outer-circle"></div>
                      <div className="timeline-middle"></div>
                      <div className="timeline-inner-circle"></div>
                    </div>
                  </div>
                  <div className="col-md-5 px-5 order-md-2">
                    <Fade left>
                      <div className="published">
                        <h5 className="text-teal my-4">May 2016</h5>
                      </div>
                      <div className="content">
                        <h3>
                          Became Webflow-exclusive within days of account
                          creation.
                        </h3>
                      </div>
                    </Fade>
                  </div>
                  <div className="col-md-5 px-5 order-md-1 mt-4 mt-md-0">
                    <div className="text-md-right">
                      <img
                        src="https://assets.website-files.com/5cc19611198b8d7bdfc5fcfb/5cd1c80477697418d0af3dca_piggy-comp.gif"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="row justify-content-center my-5 pb-5">
                  <div className="timeline-date">
                    <div className="timeline">
                      <div className="timeline-outer-circle"></div>
                      <div className="timeline-middle"></div>
                      <div className="timeline-inner-circle"></div>
                    </div>
                  </div>
                  <div className="col-md-5 px-5">
                    <Fade right>
                      <div className="published text-right">
                        <h5 className="text-teal my-4">September 2016</h5>
                      </div>
                      <div className="content text-right">
                        <h3>
                          Over 50 test websites built inside Webflow - Gained
                          years of experience in month.
                        </h3>
                      </div>
                    </Fade>
                  </div>
                  <div className="col-md-5 px-5 mt-4 mt-md-0">
                    <div className="text-right text-md-left">
                      <img
                        src="https://assets.website-files.com/5cc19611198b8d7bdfc5fcfb/5cd1c7fe24de2e38b6cd0230_kermit-comp.gif"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="row justify-content-center my-5 pb-5">
                  <div className="timeline-date">
                    <div className="timeline">
                      <div className="timeline-outer-circle"></div>
                      <div className="timeline-middle"></div>
                      <div className="timeline-inner-circle"></div>
                    </div>
                  </div>
                  <div className="col-md-5 px-5 order-md-2">
                    <Fade left>
                      <div className="published">
                        <h5 className="text-teal my-4">Jan 2017</h5>
                      </div>
                      <div className="content">
                        <h3>
                          HelloSign becomes a Finsweet client. It's an instant
                          love connection!
                        </h3>
                      </div>
                    </Fade>
                  </div>
                  <div className="col-md-5 px-5 order-md-1 mt-4 mt-md-0">
                    <div className="text-md-right">
                      <img
                        src="https://assets.website-files.com/5cc19611198b8d7bdfc5fcfb/5cd1c7fc1d0add80c409faf6_dance-comp.gif"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="row justify-content-center my-5 pb-5">
                  <div className="timeline-date">
                    <div className="timeline">
                      <div className="timeline-outer-circle"></div>
                      <div className="timeline-middle"></div>
                      <div className="timeline-inner-circle"></div>
                    </div>
                  </div>
                  <div className="col-md-5 px-5">
                    <Fade right>
                      <div className="published text-right">
                        <h5 className="text-teal my-4">June 2017</h5>
                      </div>
                      <div className="content text-right">
                        <h3>Invited to Webflow Experts program. F'in yea!</h3>
                      </div>
                    </Fade>
                  </div>
                  <div className="col-md-5 px-5 mt-4 mt-md-0">
                    <div className="text-right text-md-left">
                      <img
                        src="https://assets.website-files.com/5cc19611198b8d7bdfc5fcfb/5cd1c80f373c95edb0dacbd5_king-comp.gif"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="row justify-content-center my-5 pb-5">
                  <div className="timeline-date">
                    <div className="timeline">
                      <div className="timeline-outer-circle"></div>
                      <div className="timeline-middle"></div>
                      <div className="timeline-inner-circle"></div>
                    </div>
                  </div>
                  <div className="col-md-5 px-5 order-md-2">
                    <Fade left>
                      <div className="published">
                        <h5 className="text-teal my-4">Jan 2018</h5>
                      </div>
                      <div className="content">
                        <h3>
                          Finsweet reports over 255% growth in revenue from year
                          1 to year 2.
                        </h3>
                      </div>
                    </Fade>
                  </div>
                  <div className="col-md-5 px-5 order-md-1 mt-4 mt-md-0">
                    <div className="text-md-right">
                      <img
                        src="https://assets.website-files.com/5cc19611198b8d7bdfc5fcfb/5cd1c8a6f52501e44085638d_himym-comp.gif"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="row justify-content-center my-5 pb-5">
                  <div className="timeline-date">
                    <div className="timeline">
                      <div className="timeline-outer-circle"></div>
                      <div className="timeline-middle"></div>
                      <div className="timeline-inner-circle"></div>
                    </div>
                  </div>
                  <div className="col-md-5 px-5">
                    <Fade right>
                      <div className="published text-right">
                        <h5 className="text-teal my-4">May 2018</h5>
                      </div>
                      <div className="content text-right">
                        <h3>
                          Finsweet has full service team at full-time capacity.
                        </h3>
                      </div>
                    </Fade>
                  </div>
                  <div className="col-md-5 px-5 mt-4 mt-md-0">
                    <div className="text-right text-md-left">
                      <img
                        src="https://assets.website-files.com/5cc19611198b8d7bdfc5fcfb/5cd1c801f525013a5e856281_anchor-comp.gif"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="row justify-content-center my-5 pb-5">
                  <div className="timeline-date">
                    <div className="timeline">
                      <div className="timeline-outer-circle"></div>
                      <div className="timeline-middle"></div>
                      <div className="timeline-inner-circle"></div>
                    </div>
                  </div>
                  <div className="col-md-5 px-5 order-md-2">
                    <Fade left>
                      <div className="published">
                        <h5 className="text-teal my-4">Jan 2019</h5>
                      </div>
                      <div className="content">
                        <h3>
                          Finsweet reports over 215% growth in revenue from year
                          2 to year 3. Time to grow the team!
                        </h3>
                      </div>
                    </Fade>
                  </div>
                  <div className="col-md-5 px-5 order-md-1 mt-4 mt-md-0">
                    <div className="text-md-right">
                      <img
                        src="https://assets.website-files.com/5cc19611198b8d7bdfc5fcfb/5cc24a74583fcf7a44982c89_gif10.gif"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="row justify-content-center my-5 pb-5">
                  <div className="timeline-date">
                    <div className="timeline">
                      <div className="timeline-outer-circle"></div>
                      <div className="timeline-middle"></div>
                      <div className="timeline-inner-circle"></div>
                    </div>
                  </div>
                  <div className="col-md-5 px-5">
                    <Fade right>
                      <div className="published text-right">
                        <h5 className="text-teal my-4">Jan 2019</h5>
                      </div>
                      <div className="content text-right">
                        <h3>
                          Dropbox acquires Finsweet client, HelloSign. Dropbox
                          is now a client of Finsweet. Super Awesome.
                        </h3>
                      </div>
                    </Fade>
                  </div>
                  <div className="col-md-5 px-5 mt-4 mt-md-0">
                    <div className="text-right text-md-left">
                      <img
                        src="https://assets.website-files.com/5cc19611198b8d7bdfc5fcfb/5cd1c806f5250104a9856284_office-hs.gif"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="row justify-content-center my-5 pb-5">
                  <div className="timeline-date">
                    <div className="timeline">
                      <div className="timeline-outer-circle"></div>
                      <div className="timeline-middle"></div>
                      <div className="timeline-inner-circle"></div>
                    </div>
                  </div>
                  <div className="col-md-5 px-5 order-md-2">
                    <div className="content">
                      <Fade left>
                        <div className="published">
                          <h5 className="text-teal my-4">Feb 2019</h5>
                        </div>
                        <div className="content">
                          <h3>
                            Finsweet grows to 7 kickass full time employees.
                          </h3>
                        </div>
                      </Fade>
                    </div>
                  </div>
                  <div className="col-md-5 px-5 order-md-1 mt-4 mt-md-0">
                    <div className="text-md-right">
                      <img
                        src="https://assets.website-files.com/5cc19611198b8d7bdfc5fcfb/5cc24a48f622763b2dff9eb9_gif9.gif"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="row justify-content-center my-5 pb-5">
                  <div className="timeline-date">
                    <div className="timeline">
                      <div className="timeline-outer-circle"></div>
                      <div className="timeline-middle"></div>
                      <div className="timeline-inner-circle"></div>
                    </div>
                  </div>
                  <div className="col-md-5 px-5">
                    <Fade right>
                      <div className="published text-right">
                        <h5 className="text-teal my-4">June 2019</h5>
                      </div>
                      <div className="content text-right">
                        <h3>
                          Finsweet starts actively working with Intuit. Yes,
                          Intuit.
                        </h3>
                      </div>
                    </Fade>
                  </div>
                  <div className="col-md-5 px-5 mt-4 mt-md-0">
                    <div className="text-right text-md-left">
                      <img
                        src="https://assets.website-files.com/5cc19611198b8d7bdfc5fcfb/5d31f6dd1d4e28660402bff2_rswanson-gif.gif"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="row justify-content-center my-5 pb-5">
                  <div className="timeline-date">
                    <div className="timeline">
                      <div className="timeline-outer-circle"></div>
                      <div className="timeline-middle"></div>
                      <div className="timeline-inner-circle"></div>
                    </div>
                  </div>
                  <div className="col-md-5 px-5 order-md-2">
                    <Fade left>
                      <div className="published">
                        <h5 className="text-teal my-4">Nov 2019</h5>
                      </div>
                      <div className="content">
                        <h3>
                          Finsweet grows to 13 kickass full time employees.
                        </h3>
                      </div>
                    </Fade>
                  </div>
                  <div className="col-md-5 px-5 order-md-1 mt-4 mt-md-0">
                    <div className="text-md-right">
                      <img
                        src="https://assets.website-files.com/5cc19611198b8d7bdfc5fcfb/5e8fb6495678120f6778c1de_story-employees-2.gif"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="row justify-content-center my-5 pb-5">
                  <div className="timeline-date">
                    <div className="timeline">
                      <div className="timeline-outer-circle"></div>
                      <div className="timeline-middle"></div>
                      <div className="timeline-inner-circle"></div>
                    </div>
                  </div>
                  <div className="col-md-5 px-5">
                    <Fade right>
                      <div className="published text-right">
                        <h5 className="text-teal my-4">Jan 2020</h5>
                      </div>
                      <div className="content text-right">
                        <h3>
                          Finsweet was offered $1,240,000 for a buy out. Turned
                          it down.
                        </h3>
                      </div>
                    </Fade>
                  </div>
                  <div className="col-md-5 px-5 mt-4 mt-md-0">
                    <div className="text-right text-md-left">
                      <img
                        src="https://assets.website-files.com/5cc19611198b8d7bdfc5fcfb/5e8fb6b90ae17f840daa6f76_story-buyout2.gif"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="row justify-content-center my-5 pb-5">
                  <div className="timeline-date">
                    <div className="timeline">
                      <div className="timeline-outer-circle"></div>
                      <div className="timeline-middle"></div>
                      <div className="timeline-inner-circle"></div>
                    </div>
                  </div>
                  <div className="col-md-5 px-5 order-md-2">
                    <Fade left>
                      <div className="published">
                        <h5 className="text-teal my-4">May 2020</h5>
                      </div>
                      <div className="content">
                        <h3>
                          F'in sweet CMS Library for Webflow is released to the
                          public for free! WTF.
                        </h3>
                      </div>
                    </Fade>
                  </div>
                  <div className="col-md-5 px-5 order-md-1 mt-4 mt-md-0">
                    <div className="text-md-right">
                      <img
                        src="https://assets.website-files.com/5cc19611198b8d7bdfc5fcfb/5ebee8931ccdbb2d7aa9c94b_timeline-smart1.gif"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="row justify-content-center my-5 pb-5">
                  <div className="timeline-date">
                    <div className="timeline">
                      <div className="timeline-outer-circle"></div>
                      <div className="timeline-middle"></div>
                      <div className="timeline-inner-circle"></div>
                    </div>
                  </div>
                  <div className="col-md-5 px-5">
                    <Fade right>
                      <div className="published text-right">
                        <h5 className="text-teal my-4">May 2018</h5>
                      </div>
                      <div className="content text-right">
                        <h3>
                          Finsweet has full service team at full-time capacity.
                        </h3>
                      </div>
                    </Fade>
                  </div>
                  <div className="col-md-5 px-5 mt-4 mt-md-0">
                    <div className="text-right text-md-left">
                      <img
                        src="https://assets.website-files.com/5cc19611198b8d7bdfc5fcfb/5cd1c801f525013a5e856281_anchor-comp.gif"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Zoom>
              <div className="d-flex justify-content-center align-items-center">
                <div className="overlay-img d-flex align-items-center">
                  <div className="blob pulse-2"></div>
                  <div className="blob pulse-1"></div>
                  <div className="blob-overlay bg-red-gradient"></div>
                  <Link to="/quiz">Get a Price</Link>
                </div>
              </div>
            </Zoom>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Story;
