import React from "react"

const ReadingProgress = ({ target }) => {
  const [readingProgress, setReadingProgress] = React.useState(0);

  

  const scrollListener = () => {
    if (!target.current) {
      return;
    }

    const element         = target.current;
    const totalHeight     = element.clientHeight - element.offsetTop - (window.innerHeight);
    const windowScrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    let x = window.matchMedia("(min-width: 768px)")
   
    // if (windowScrollTop > totalHeight) {
    //   return setReadingProgress(100);
    // }

    if(x.matches) {
      console.log(x.matches)
      setReadingProgress((windowScrollTop / totalHeight) * 80);
    }
    else {
      console.log(x.matches)
      setReadingProgress((windowScrollTop / totalHeight) * 85);
    }


   
    
  };
  
  React.useEffect(() => {
    if(window) {
      window.addEventListener("scroll", scrollListener);
      return () => window.removeEventListener("scroll", scrollListener);  
    }
    
  });

  return <div className={`reading-progress-bar`} style={{height: `${readingProgress}%`}} />
}

export default ReadingProgress